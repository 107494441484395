.swiper-wrapper .swiper-slide .image-show {
  height: 29.76vw !important;
  width: 48vw !important;
}
@media screen and (max-width: 430px) {
  .swiper-wrapper .swiper-slide .image-show {
    height: 56.27vw !important;
    width: 100vw !important;
  }
}
