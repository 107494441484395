.top-bar {
  height: 7.66vw;
  background-color: #d9d9d9;
}
.nav-layout .top-bar {
  width: 100vw !important;
}
.link-show {
  cursor: pointer;
  text-decoration: unset;
  margin: 1.6vw;
  margin-left: 3vw;
  margin-right: 3vw;
  font-size: 2.3vw;
  font-weight: bold;
  color: black;
  font-family: "Sukhumvit-Medium", sans-serif;
  font-weight: 500;
  width: max-content;
}
.berger-item .active,
.berger-item .link-show:hover {
  color: white !important;
  text-decoration-thickness: 1.2vw !important;
  text-decoration-color: white;
  text-decoration: underline;
}
.change-language-mobile > .show-text .active {
  color: white !important;
  text-decoration-thickness: 1.2vw !important;
  text-decoration-color: white;
  text-decoration: underline;
}
.link-show:hover,
.top-bar-nav .active {
  /* text-decoration: underline; */
  /* text-decoration-color: #e09132;
  color: #e09132 !important; */
  /* text-decoration-color: white; */
  color: rgb(0, 0, 0) !important;
  /* text-decoration-thickness: 0.3vw; */
  font-family: "Sukhumvit-Bold", sans-serif;
  font-weight: bold;
}
.show-logo {
  left: 5vw;
  top: 50%;
  width: 5.2vw !important;
  height: 5.2vw !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  transform: translateY(-50%);
}
.show-logo-phone {
  display: none;
}
.top-bar-desktop {
  height: 8vw !important;
  margin-bottom: 2.5vw !important;
}
.top-bar-desktop .show-logo-desktop {
  margin-top: 1vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8vw !important;
  height: 8vw !important;
}
.top-bar-nav {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.berger-show {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.animate {
  animation: moveAndGrow 0.5s forwards;
}
.animate-back {
  animation: backMove 0.5s forwards;
}
.item-forward {
  animation: moveAndGrow 0.5s forwards;
}
.item-back {
  animation: backMove 0.5s forwards;
}
.change-language {
  position: absolute;
  right: 5vw;
  top: 50%;
  font-size: 1.6vw;
  padding: 1.6vw;
  transform: translateY(-50%);
}

@keyframes moveAndGrow {
  0% {
    left: 0;
    width: 0;
  }
  100% {
    left: auto;
    width: 70vw;
  }
}
@keyframes backMove {
  0% {
    left: auto;
    width: 70vw;
  }
  100% {
    left: 0;
    width: 0;
  }
}
@keyframes itemForward {
  0% {
    width: 0vw;
  }
  100% {
    width: 100vw;
  }
}
@keyframes itemBack {
  0% {
    width: 100vw;
  }
  100% {
    width: 0vw;
  }
}

.berger-menu {
  position: absolute;
  background-color: black;
  width: 0vw;
  height: 100vh;
  z-index: 9999999;
}
.berger-item {
  display: flex;
  flex-direction: column;
  width: 0vw;
  overflow: hidden;
  z-index: 999999;
  text-align: center;
  align-items: center;
}
.berger-item .link-show {
  color: white;
  font-size: 6vw;
}
.berger-item .link-show-header {
  margin-top: 5vw;
  color: white;
  font-size: 8vw;
}
.change-language {
  display: flex;
}
.show-text {
  cursor: pointer;
}
.change-language .active {
  /* color: #e09132; */
  color: white;
}
.change-language-mobile {
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 6vw;
  margin: 1vw;
}
@media (max-width: 430px) {
  .show-logo-phone {
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .top-bar-desktop {
    display: none;
  }
  .change-language {
    display: none;
  }
  .berger-show {
    display: block;
  }
  .top-bar-nav {
    display: none;
  }
  .top-bar .show-logo {
    width: 12vw !important;
    height: 12vw !important;
  }
  .top-bar {
    height: 14.43vw !important;
  }
  .berger-item .active,
  .link-show:hover,
  .top-bar-nav .active {
    text-decoration-thickness: 1.2vw;
  }
}
