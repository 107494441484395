.plot .plot-slider .swiper-wrapper .swiper-slide .image-show {
  object-fit: contain;
  width: 75vw !important;
  height: 45.925vw !important;
}
.plot .text-show {
  background-color: #58832e;
}
.plot .plot-container {
  height: 100%;
  width: 100vw;
  margin-top: 10vw;
  height: 45.925vw !important;
}
.plot .plot-container .swiper {
  height: 45.925vw !important;
}
.plot .plot-slider {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.plot .plot-text {
  color: #fff !important;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}
.plot .text-show {
  padding: 2vw;
  padding-left: 4.5vw;
}
.plot .text-show-fr {
  padding-left: 2vw;
}
.plot .text-show-de {
  padding-left: 3vw;
}
.title-text {
  font-size: 1.4vw;
  line-height: 2vw;
}
.detail-text {
  font-size: 1.2vw;
  margin-top: 0.4vw;
  margin-left: 0.8vw;
}
.show-detial {
  overflow: hidden;
}
.plot-flex {
  margin-bottom: 1.2vw;
}
.plot .circle-plot {
  background-color: black;
  border-radius: 10vw;
  text-align: center;
  width: 1.8vw;
  height: 1.8vw;
  margin-top: 0.3vw;
}
.plot .plot-line {
  position: relative;
}
.plot-line .plot-line-1 {
  border: 0.15vw solid black !important;
  transform: skewX(25deg);
  width: 0.15vw;
  height: 2vw;
  left: -0.5vw;
  top: 0.5vw;
  position: absolute !important;
}
.plot-line .plot-line-2 {
  border: 0.15vw solid black !important;
  width: 17vw;
  height: 0.15vw;
  left: 0vw;
  bottom: -0.34vw;
  position: absolute !important;
}
.plot .text-show-fr .plot-line-2 {
  width: 22vw;
}
.plot .text-show-de .plot-line-2 {
  width: 20vw;
}

@media screen and (max-width: 768px) and (min-width: 431px) {
  .plot .plot-flex {
    margin-left: 0.04vw;
  }
  .plot-line .plot-line-2 {
    bottom: -0.45vw;
  }
  .plot .plot-line-1 {
    scale: 0.8;
    width: 0.15vw;
    height: 2.5vw;
    top: 0.3vw;
  }
  .plot .text-show-fr .plot-line-2 {
    scale: 0.8;
    width: 27vw;
    height: 0.15vw;
    left: -2.65vw;
  }
  .plot .text-show-de .plot-line-2 {
    scale: 0.8;
    width: 25vw;
    height: 0.15vw;
    left: -2.55vw;
  }
  .title-text {
    line-height: 1.9vw;
  }
}
@media screen and (max-width: 430px) and (min-width: 250px) {
  .plot .plot-flex {
    margin-left: 0.04vw;
  }
  .plot-line .plot-line-2 {
    bottom: -0.45vw;
  }
  .plot .plot-line-1 {
    scale: 0.5;
    width: 0.15vw;
    height: 4.5vw;
    top: -0.9vw;
  }
  .plot .text-show-fr .plot-line-2 {
    scale: 0.5;
    width: 43.5vw;
    height: 0.15vw;
    left: -10.73vw;
  }
  .plot .text-show-de .plot-line-2 {
    scale: 0.5;
    width: 40.5vw;
    height: 0.15vw;
    left: -9.9vw;
  }
  .title-text {
    line-height: 1.9vw;
  }
}
