.about .about-title-div {
  position: relative;
  height: 48.44vw;
  /* width: 100vw; */
}
.about .about-title-div .about-text-div {
  position: absolute;
  top: 40%;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
}
.about .about-title-div .about-title {
  font-size: 4.17vw;
  opacity: 0;
  animation: drop 0.4s linear forwards;
}
.about .about-title-div .about-title:nth-child(2) {
  animation-delay: 0.2s;
}

.about .about-title-div .about-title:nth-child(3) {
  animation-delay: 0.4s;
}

.about .about-title-div .about-title:nth-child(4) {
  animation-delay: 0.6s;
}

.about .about-title-div .about-title:nth-child(5) {
  animation-delay: 0.8s;
}

.about .about-title-div .about-title:nth-child(6) {
  animation-delay: 1s;
  margin-left: 3vw;
}
.about .about-title-div .about-title:nth-child(7) {
  animation-delay: 1.2s;
}

/* th */
.about .about-title-div .about-title-th {
  font-size: 4.17vw;
  opacity: 0;
  animation: drop 0.4s linear forwards;
}
.about .about-title-div .about-title-th:nth-child(2) {
  animation-delay: 0.2s;
  margin-left: 0.6vw;
}

.about .about-title-div .about-title-th:nth-child(3) {
  animation-delay: 0.4s;
}

.about .about-title-div .about-title-th:nth-child(4) {
  animation-delay: 0.6s;
  margin-left: -0.3vw;
}

.about .about-title-div .about-title-th:nth-child(5) {
  animation-delay: 0.8s;
}

.about .about-title-div .about-title-th:nth-child(6) {
  animation-delay: 1s;
}
.about .about-title-div .about-title-th:nth-child(7) {
  animation-delay: 1.2s;
  margin-left: -0.6vw;
}
.about .about-title-div .about-title-th:nth-child(8) {
  animation-delay: 1.4s;
}
.about .about-title-div .about-title-th:nth-child(9) {
  animation-delay: 1.6s;
}

/* th */
.mission {
  background-color: #a58e74;
  padding: 3vw;
  padding-right: 5.1vw;
  padding-left: 10vw;
  overflow: hidden;
}
.mission-row {
  display: flex;
  color: white;
}
.mission-row .mission-title {
  font-size: 4.17vw;
  width: 40vw;
}
.mission-row .mission-detail {
  font-size: 1.823vw;
  width: 60vw;
}
.vission {
  /* background-color: #a58e74; */
  padding: 3vw;
  padding-right: 5.1vw;
  padding-left: 10vw;
  position: relative;
  overflow: hidden;
}
.vission img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(50%);
  z-index: -9999;
}
.vission-row {
  display: flex;
  color: white;
}
.vission-row .vission-title {
  font-size: 4.17vw;
  width: 40vw;
}
.vission-row .vission-detail {
  font-size: 1.823vw;
  width: 60vw;
}

.process {
  padding: 3vw;
  padding-right: 5.1vw;
  padding-left: 10vw;
  overflow: hidden;
}
.process-row {
  display: flex;
}
.process-row .process-title {
  font-size: 4.17vw;
  width: 40vw;
}
.process-row .process-detail {
  font-size: 1.823vw;
  width: 60vw;
}
.our-team {
  margin-top: 7vw;
  margin-bottom: 10vw;
}
.our-team .our-team-title {
  font-size: 4.17vw;
  text-align: center;
}
.our-team .our-team-row {
  margin-top: 3vw;
  display: flex;
}
.our-team .our-team-picture {
  position: relative;
  width: 25%;
}
.our-team .our-team-picture .image-show {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.our-team .our-team-picture .image-show .overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0%;
  height: 0%;
  transform: translate(50%, 50%);
  background-color: rgba(0, 0, 0, 0.5);
}
.our-team .our-team-picture .image-show img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.our-team .our-team-picture .image-show:last-child img {
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.our-team .our-team-picture .image-show:hover > .overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 150%;
  height: 150%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: show-overlay 0.7s forwards;
}
.show-title-team {
  display: none;
}
@media screen and (max-width: 768px) {
  .background-about {
    height: 65vw !important;
  }
  .image-circle {
    margin-top: 10vw !important;
    width: 30vw !important;
    height: 30vw !important;
  }
  .about-text-sector .main-text {
    font-size: 6vw;
  }
  .about-text-sector .position-text {
    font-size: 4vw;
  }
  .our-team .our-team-picture .image-show:hover > .overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150%;
    height: 150%;
    background-color: unset;
    animation: unset;
  }
  .show-title-team {
    display: block;
    color: black;
    margin-top: 1vw;
  }
  .main-me-text {
    font-size: 6vw !important;
  }
  .detail-me-text {
    margin-top: 10vw !important;
    font-size: 4vw !important;
    width: 80vw;
  }
  .about-me {
    height: 68vw !important;
  }
  .box-sector {
    flex-direction: column;
  }
  .box-sector .box-show {
    margin-top: 10vw;
    height: 40vw;
    width: 80vw !important;
  }
  .box-main-text {
    font-size: 6vw !important;
  }
  .box-detail-text {
    font-size: 4vw !important;
  }
}
.hover-show {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 21vw;
  padding-left: 3vw;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.hover-show .show-name-title {
  font-size: 1.6vw;
  color: white;
}
.hover-show .show-sub-name-title {
  font-size: 1.041vw;
  color: white;
}
@keyframes show-overlay {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0;
    border-radius: 0px;
  }
  100% {
    width: 300%;
    height: 300%;
    border-radius: 100%;
    opacity: 1;
  }
}
@keyframes drop {
  0% {
    transform: translate(-50%, -20vw) scaleY(0.9);
  }
  5% {
    opacity: 0.7;
  }
  50% {
    transform: translate(-50%, 0px) scaleY(1);
    opacity: 1;
  }
  65% {
    transform: translate(-50%, -1.7vw) scaleY(0.9);
  }
  75% {
    transform: translate(-50%, -2.2vw) scaleY(0.9);
  }
  100% {
    transform: translate(-50%, 0px) scaleY(1);
    opacity: 1;
  }
}
.background-about {
  position: relative;
  width: 100vw;
  height: 25vw;
  background-color: #424530;
}
.image-circle {
  width: 8vw;
  height: 8vw;
  border-radius: 20vw;
  margin-top: 5vw;
}
.about-text-sector {
  margin: 2vw;
  text-align: center;
}
.main-text {
  font-size: 1.6vw;
  color: white;
}
.position-text {
  font-size: 1.1vw;
  color: rgb(249, 248, 248);
}
.about-me {
  position: relative;
  height: 16vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
}
.main-me-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  font-size: 1.6vw;
}
.about-me-sector {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.main-me-text {
  font-size: 1.6vw;
}
.detail-me-text {
  margin-top: 3vw;
  font-size: 1.2vw;
}
.box-sector {
  display: flex;
  margin-left: 9.5vw;
  margin-right: 9.5vw;
  margin-bottom: 4vw;
}
.box-sector .box-show {
  width: 29vw;
  padding: 2vw;
  margin-left: 1vw;
  margin-right: 1vw;
  border: 1px solid #707070;
  background-color: #ffefcd;
}
.box-sector .box-show .box-main-text {
  font-size: 1.6vw;
}
.box-sector .box-show .box-detail-text {
  font-size: 1.2vw;
}
/* .about-me:nth-child(3) {
  background-color: #a58e77;
  color: white;
} */
.our-vision {
  background-color: #a58e77;
  color: white;
}
.half-show {
  scale: 0.5;
}
