.list-container .text-sector {
  position: absolute;
  width: max-content;
  overflow: hidden;
  right: 0;
  top: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.list-container {
  position: relative;
  height: 100%;
}
.list-container .text-sector .list-title {
  /* margin-top: 6vw; */
  font-size: 1.9vw;
  /* width: 25vw; */
  color: white;
}
.list-container .text-sector .list-sub-title {
  font-size: 1.6vw;
  color: white;
}
.list-container .text-sector .list-detail {
  font-size: 1.3vw;
  color: white;
}
.image-list {
  margin-left: 0px !important;
}
.image-list .image-part {
  height: 37.42vw;
  width: 25%;
  background-size: cover;
  background-repeat: no-repeat;
}
.show-image-div {
  overflow: hidden;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.parent-container {
  background-color: #8c9e93;
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .list-container .text-sector .list-title {
    font-size: 2vw;
  }
  .list-container .text-sector .list-sub-title {
    font-size: 1.8vw;
  }
  .list-container .text-sector .list-detail {
    font-size: 1.5vw;
  }
  .list-container .text-sector .list-sub-title-fr {
    font-size: 1.6vw !important;
  }
}
@media screen and (max-width: 768px) and (min-width: 431px) {
  .list-container .text-sector .list-title {
    font-size: 2.2vw;
  }
  .list-container .text-sector .list-sub-title {
    font-size: 2vw;
  }
  .list-container .text-sector .list-detail {
    font-size: 1.7vw;
  }
  .list-container .text-sector .list-sub-title-fr {
    font-size: 1.6vw !important;
  }
}
@media screen and (max-width: 430px) and (min-width: 250px) {
  .list-container .text-sector .list-title {
    font-size: 2.2vw;
  }
  .list-container .text-sector .list-sub-title {
    font-size: 2vw;
  }
  .list-container .text-sector .list-detail {
    font-size: 1.7vw;
  }
  .list-container .text-sector .list-sub-title-fr {
    font-size: 1.7vw !important;
  }
}
