.component-container {
  margin-top: 10vw;
  position: relative;
  height: 30vw;
}
.component-container .image-show-stack-base {
  position: absolute;
  left: 12%;
  transform: translateX(-50%);
  bottom: 0vw;
  /* scale: 1.2; */
  width: 26.9443vw;
  height: 16.128vw;
}
.component-container .image-show-stack-1 {
  position: absolute;
  left: 12%;
  transform: translateX(-50%);
  bottom: 2.47vw;
  /* scale: 1.2; */
  /* top: -40px; */
  width: 26.9443vw;
  height: 16.128vw;
}
.component-container .image-show-stack-2 {
  position: absolute;
  left: 12%;
  transform: translateX(-50%);
  bottom: 3vw;
  /* scale: 1.2; */
  /* top: -50px; */
  width: 26.9443vw;
  height: 16.128vw;
  /* display: none; */
}
.component-container .image-show-stack-3 {
  position: absolute;
  left: 12%;
  transform: translateX(-50%);
  bottom: 1.2vw;
  /* scale: 1.2; */
  /* top: -21px; */
  width: 26.9443vw;
  height: 16.128vw;
  /* display: none; */
}
.text-sector {
  position: absolute;
  right: 10vw;
  top: -4vw;
}
.project .text-sector .main-text {
  color: black;
  font-size: 1.3vw;
  margin-top: 3vw;
}
.text-sector .main-text:first-child {
  font-size: 1.3vw;
  margin-top: -6vw;
}
.text-sector .detail-text {
  font-size: 1.1vw;
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .text-sector {
    right: 7vw;
  }
  .component-container {
    margin-top: 18vw;
  }
  .text-sector .main-text:first-child {
    margin-top: -11vw;
  }
  .text-sector .main-text {
    margin-top: 2vw;
  }
  .text-sector .main-text {
    font-size: 1.9vw !important;
  }
  .text-sector li {
    font-size: 1.5vw !important;
  }
  .component-container .image-show-stack-base {
    width: 28.9443vw;
    height: 18.128vw;
  }
  .component-container .image-show-stack-1 {
    width: 28.9443vw;
    height: 18.128vw;
  }
  .component-container .image-show-stack-2 {
    width: 28.9443vw;
    height: 18.128vw;
  }
  .component-container .image-show-stack-3 {
    width: 28.9443vw;
    height: 18.128vw;
  }
}
@media screen and (max-width: 768px) and (min-width: 431px) {
  .text-sector {
    right: 7vw;
  }
  .component-container {
    margin-top: 28vw;
  }
  .text-sector .main-text {
    margin-top: 2vw;
    font-size: 1.9vw !important;
  }
  .text-sector li {
    font-size: 1.5vw !important;
  }
  .text-sector ul {
    padding-left: 5vw !important;
    margin-bottom: 2vw !important;
  }
  .text-sector .main-text:first-child {
    margin-top: -20vw;
  }
  .component-container .image-show-stack-base {
    left: 8%;
    width: 32.9443vw;
    height: 22.128vw;
  }
  .component-container .image-show-stack-1 {
    left: 8%;
    width: 32.9443vw;
    height: 22.128vw;
  }
  .component-container .image-show-stack-2 {
    left: 8%;
    width: 32.9443vw;
    height: 22.128vw;
  }
  .component-container .image-show-stack-3 {
    left: 8%;
    width: 32.9443vw;
    height: 22.128vw;
  }
}
@media screen and (max-width: 430px) and (min-width: 250px) {
  .text-sector {
    right: 7vw;
  }
  .component-container {
    margin-top: 28vw;
  }
  .text-sector .main-text {
    margin-top: 2vw;
  }
  .text-sector ul {
    padding-left: 5vw !important;
    margin-bottom: 2vw !important;
  }
  .text-sector .main-text:first-child {
    margin-top: -20vw;
  }
  .text-sector .main-text {
    font-size: 1.9vw !important;
  }
  .text-sector li {
    font-size: 1.5vw !important;
  }
  .component-container .image-show-stack-base {
    left: 8%;
    width: 32.9443vw;
    height: 22.128vw;
  }
  .component-container .image-show-stack-1 {
    left: 8%;
    width: 32.9443vw;
    height: 22.128vw;
  }
  .component-container .image-show-stack-2 {
    left: 8%;
    width: 32.9443vw;
    height: 22.128vw;
  }
  .component-container .image-show-stack-3 {
    left: 8%;
    width: 32.9443vw;
    height: 22.128vw;
  }
}
