:root {
  --animation-slide: 4vw;
}
.name-web {
  font-size: 3.13vw;
  /* font-weight: bold; */
  background-color: #424530;
  color: white;
  position: absolute;
  top: 22%;
  left: 10%;
  z-index: 99999;
}
.swiper-slide-active {
  display: flex;
  justify-content: center;
}
.project .swiper-wrapper .swiper-slide .image-show {
  height: 100%;
  width: 75vw !important;
  height: 100% !important;
  margin-left: 0.1vw;
}
.main-index .swiper-wrapper .swiper-slide .image-show {
  height: 100%;
  width: 100vw !important;
  height: 100% !important;
}
.image-show-our {
  height: 46vw;
  width: 100vw;
  display: flex;
}
.image-show-our .img-item {
  height: 46vw;
  width: 33.333333333333vw;
  position: relative;
}

.image-show-our .img-item .show-name-div {
  height: 46vw;
  width: 100%;
}
.image-show-our .hover-text {
  padding-left: 4vw;
  padding-top: 39vw !important;
}
.image-show-our .show-name-div .show-img-item {
  height: 46vw;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -99;
}
.image-show-our .show-name-div {
  position: relative;
  color: white;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.fiter-drakness {
  height: 46vw;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.show-name-div:hover > .fiter-drakness {
  background-color: rgba(0, 0, 0, 0.5);
  animation: slide-up-fade-in-height ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.image-show-our .show-name-title {
  font-size: 1.6vw;
}
.image-show-our .show-name-subtitle {
  font-size: 1.041vw;
  /* font-size: 20px; */
}
.image-show-our .show-img-icon {
  width: 4.473vw;
  height: 0.833vw;
}
.show-image-btn {
  display: flex;
  position: absolute;
  right: 7%;
  bottom: 10%;
  z-index: 99999;
}
.image-btn {
  border: unset;
  cursor: pointer;
}
.i-con-white {
  font-size: 4vw;
  padding: 1vw 0.5vw;
  background-color: white;
}
.section-space {
  margin-top: 5vw;
}
.text-main {
  font-size: 3.13vw;
  /* font-size:60px; */
  /* font-weight: bold; */
  margin-bottom: 3vw;
}
.text-line {
  margin-top: 10px;
  border-top: 10px solid #e09132;
  margin-bottom: 3.13vw;
  width: 25vw;
}
.full-width-img {
  width: 100vw;
}
.full-width {
  width: 100%;
}
.view-show {
  margin-top: 4vw;
  text-align: center;
}
.view-text {
  font-size: 2.1vw;
  /* font-size:40px; */
  color: #e09132;
  text-decoration: underline double;
}
.what-div {
  min-width: max-content;
  font-size: 2.605vw;
  /* font-size:50px; */
  /* font-weight: bold; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}
.what-div-detail {
  font-size: 1.6vw;
  /* font-size:30px; */
  /* font-weight: 200; */
  max-width: 750px;
}
.show-what-make {
  width: 100%;
  margin-top: 4vw;
}
.line-what {
  width: 85%;
  border-top: 10px solid #e09132;
  position: absolute;
  top: 75%;
  left: 0;
  transform: translate(0, -75%);
}
.space-sector {
  margin-top: 2.605vw;
}
.slot-line {
  position: absolute;
  height: 738px;
  border-top: 10px solid #e09132;
  border-left: 10px solid #e09132;
  border-bottom: 10px solid #e09132;
  width: 30%;
  right: 0;
  z-index: -1;
}
.slot-square {
  padding-right: 9%;
  padding-left: 9%;
  padding-top: 10.5vw;
  padding-bottom: 10.5vw;
}
.show-square {
  padding: 2.05vw;
  border: 1px solid #707070;
  background-color: #ffefcd;
  height: 100%;
}
.slot-circle {
  margin-left: -2.344vw;
  margin-top: 3.65vw;
  /* margin-top: 70px; */
  width: 3.13vw;
  height: 3.13vw;
}

.icon-sqare {
  position: relative;
  width: 4.17vw;
  /* width: 80px; */
  height: 4.17vw;
  z-index: 99999999;
}

.square-text {
  /* font-weight: bold; */
  font-size: 1.6vw;
  margin-top: 1.6vw;
  margin-bottom: 1.6vw;
}
.square-detail {
  font-size: 0.782vw;
}
.why-choose {
  margin-top: 14vw;
  position: relative;
}
.why-choose-image {
  position: absolute;
  right: 0;
  top: -6vw;
  /* top: -120px; */
  width: 29.95vw;
  height: 39.011vw;
}
.why-choose-image-2 {
  position: absolute;
  right: 17vw;
  bottom: -10.3vw;
  height: 13.81vw;
  width: 24.85vw;
}
.background-why-choose {
  margin-top: 3.13vw;
  padding: 4.17vw;
  /* padding-left: 150px; */
  padding-left: 7.813vw;
  color: #fff;
  background-color: #424530;
  margin-bottom: 6vw;
}
.main-why {
  /* font-weight: 700; */
  font-size: 1.6vw;
  margin-bottom: 2.605vw;
}
.second-why {
  /* font-weight: bold; */
  font-size: 2.605vw;
  margin-bottom: 2.605vw;
  max-width: 36.5vw;
}
.detail-why {
  font-size: 1.6vw;
  /* font-weight: 100; */
  max-width: 36.5vw;
}
.our-project {
  margin-top: 20.85vw;
  margin-bottom: 17vw;
  position: relative;
}
.our-project-text {
  position: absolute;
  left: 65%;
  top: 20%;
}
.our-main {
  font-size: 3.13vw;
  /* font-weight: bold; */
  text-decoration: underline;
  text-decoration-color: #e09132;
  text-decoration-thickness: 0.8vw;
}
.our-list {
  font-size: 1.823vw;
  /* font-size: 35px; */
  /* font-weight: bold; */
  color: #e09132;
  margin-top: 1.15vw;
}
.our-project-image {
  height: 29.95vw;
  width: 43.6vw;
}
.show-what-make .row {
  overflow: hidden;
}
.show-slider-image {
  position: absolute;
  right: 0;
  top: 0;
  height: 29.76vw;
  width: 48vw;
  margin: 5vw;
  /* overflow: hidden; */
}
@media (max-width: 430px) {
  .name-web {
    font-size: 4.5vw;
  }
  .show-slider-image {
    height: 56.27vw;
    width: 100vw;
    right: unset;
    margin: 0vw;
    margin-top: -1vw;
  }
  .show-image-btn {
    display: none !important;
  }
  .text-main {
    font-size: 4.67vw;
    text-align: center !important;
  }
  .view-show {
    display: none !important;
  }
  .show-what-make .row .col-6 {
    margin-top: 12vh;
    width: 100% !important;
  }
  .what-div {
    font-size: 4.67vw;
  }
  .what-div-detail {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 3.5vw;
    width: 82%;
  }
  .show-what-make {
    margin-bottom: 45vw;
  }
  .show-square {
    padding: 6vw;
    width: 70vw;
  }
  .icon-sqare {
    width: 11.7vw !important;
    height: 11.7vw !important;
  }
  .slot-circle {
    width: 8.38vw;
    height: 8.38vw;
    margin-left: -6.7vw;
    margin-top: 11.65vw;
  }
  .square-text {
    padding-top: 3vw;
    padding-bottom: 4vw;
    font-size: 4.2vw;
  }
  .square-detail {
    font-size: 1.867vw;
    /* font-size: 2.1vw; */
  }
  .slot-square .col-12 {
    margin-bottom: 8vw;
  }
  .slot-square {
    padding-right: 0px;
    padding-left: 0px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .background-why-choose {
    padding-top: 50vw;
    padding-left: 3.13vw;
  }
  .why-choose-image {
    top: -39vw !important;
    width: 53.5vw;
    height: 69.77vw;
  }
  .why-choose {
    margin-top: 35vw;
  }
  .why-choose-image-2 {
    top: 19vw;
    bottom: unset !important;
    right: 27vw;
    width: 44.42vw;
    height: 24.66vw;
  }
  .main-why {
    margin-top: 12vw;
    font-size: 2.8vw;
  }
  .second-why {
    font-size: 4.67vw;
    max-width: 100vw;
  }
  .detail-why {
    font-size: 3.5vw;
    max-width: 100vw;
  }
  .our-project {
    height: 143vw;
    margin-bottom: 35vw;
  }
  .our-project-image {
    width: 100vw;
    height: 68.61vw;
  }
  .our-project-text {
    left: 50%;
    right: unset;
    top: unset;
    bottom: -5%;
    transform: translateX(-50%);
    width: max-content;
  }
  .our-main {
    font-size: 9.3vw;
    text-decoration-thickness: 2vw;
  }
  .our-list {
    font-size: 7vw;
  }
}
.slide-up-fade-in {
  animation: slide-up-fade-in ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide-up-fade-in ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: slide-up-fade-in ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide-up-fade-in ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide-up-fade-in ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
  opacity: 0;
  opacity: 1\9;
}
.slide-up-fade-in-deley {
  animation: slide-up-fade-in ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide-up-fade-in ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: slide-up-fade-in ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide-up-fade-in ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide-up-fade-in ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
  animation-delay: 0.25s;
  opacity: 0;
  opacity: 1\9;
}
@keyframes slide-up-fade-in-height {
  0% {
    opacity: 0;
    height: 0px;
  }
  100% {
    opacity: 1;
    height: 46vw;
  }
}
@keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, var(--animation-slide));
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -moz-transform: translate(0px, var(--animation-slide));
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, var(--animation-slide));
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -o-transform: translate(0px, var(--animation-slide));
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes slide-up-fade-in {
  0% {
    opacity: 0;
    -ms-transform: translate(0px, var(--animation-slide));
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}
.show-email-send {
  padding: 4vw;
  background-color: rgba(234, 191, 127, 0.5);
  margin-bottom: 10vw;
}
.btn-j {
  background-color: #a58e74 !important;
  color: #fff !important;
}
