.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 61.3vw;
}
.swiper-slide .image-slider {
  width: 100%;
  height: 100%;
}
.show-text-slider {
  font-size: 4.66vw;
}
