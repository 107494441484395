.around-container {
  margin-top: 10vw;
  padding: 5vw;
  position: relative;
}
.around-container .detail-text {
  max-width: 30vw;
  margin-top: 2vw;
  font-size: 1.5vw;
}
.around-container .title-text {
  max-width: 30vw;
  font-size: 2.1vw;
}
.image-sector {
  position: absolute;
  top: 0;
  right: 0;
  width: max-content;
  max-width: 13vw;
  margin-top: 3vw;
}
.image-show-left {
  margin-top: 2.1vw;
  max-width: 10vw;
  cursor: pointer;
  z-index: 999999999999;
}
.show-text-left {
  position: relative;
  max-width: 30vw;
  padding: 3.5vw;
  height: 35vw;
}
.image-show-left:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border: 1px solid #e2e2e2;
}
.hover-not {
  box-shadow: unset !important;
  border: unset !important;
  cursor: unset !important;
}
.image-show-big {
  position: absolute;
  top: 0;
  right: 0;
}
.prepair {
  position: absolute;
  background-color: white;
  width: 30vw;
  height: 100%;
  top: 0;
  right: 0;
}
.text-image-sector {
  position: absolute;
  bottom: 8vw;
  left: 45vw;
  width: 31vw;
  display: flex;
  justify-content: center;
}
.image-text {
  font-size: 1.8vw;
}
@media screen and (max-width: 1420px) and (min-width: 769px) {
  .around-container {
    padding: 3vw;
  }
  .image-show-left {
    margin-top: 1.5vw;
  }
}
@media screen and (max-width: 769px) and (min-width: 430px) {
  .around-container {
    padding: 3vw;
  }
  .image-show-left {
    margin-top: 1.5vw;
  }
  .around-container .detail-text {
    font-size: 1.7vw;
    margin-top: 1vw;
  }
  .title-text-fr {
    font-size: 1.9vw !important;
  }
  .detail-text-fr {
    font-size: 1.65vw !important;
  }
  .detail-text-de {
    font-size: 1.58vw !important;
  }
  .detail-data-text-de {
    font-size: 1.48vw !important;
  }
}
@media screen and (max-width: 429px) and (min-width: 250px) {
  .around-container {
    padding: 3vw;
  }
  .image-show-left {
    margin-top: 1.5vw;
  }
  .around-container .detail-text {
    margin-top: 1vw;
    font-size: 1.65vw;
  }
  .around-container .title-text {
    font-size: 2.1vw;
  }
  .title-text-fr {
    font-size: 1.9vw !important;
  }
  .detail-text-de {
    font-size: 1.59vw !important;
  }
}
