.suk-bold {
  font-family: "Sukhumvit-Bold", sans-serif;
  font-weight: bold;
}
.suk-light {
  font-family: "Sukhumvit-Light", sans-serif;
  font-weight: 300;
}
.suk-medium {
  font-family: "Sukhumvit-Medium", sans-serif;
  font-weight: 500;
}
.suk-semibold {
  font-family: "Sukhumvit-SemiBold", sans-serif;
  font-weight: 600;
}
.suk-text {
  font-family: "Sukhumvit-Text", sans-serif;
  font-weight: 200;
}
.suk-thin {
  font-family: "Sukhumvit-Thin", sans-serif;
  font-weight: 100;
}
