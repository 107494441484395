.project {
  margin-top: 6vw;
  position: relative;
}
/* .project .sun-show {
  background: #2eb5e5;
  height: 15vw;
  margin-top: 4vw;
  width: 25vw;
  position: relative;
} */
.project .plot {
  width: 100vw;
}
.project .plot .plot-container {
  margin-left: 0vw !important;
}
.project .swiper-wrapper {
  width: 100vw !important;
}
.main-title {
  /* text-decoration: underline; */
  font-size: 3.13vw;
}
.project .main-title div:nth-child(3) {
  margin-left: 1vw;
}
.sub-title {
  font-size: 1.6vw;
  color: #e09132;
}
.div-text {
  margin-left: 12vw;
}
.project .show-image-btn {
  display: flex;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY("-50%");
  margin-left: 5vw;
  z-index: 99999;
}
.project .image-btn-left {
  display: flex;
  position: absolute;
  left: -1vw;
  top: 50%;
  transform: translateY(-50%) !important;
  margin-left: 5vw;
  z-index: 99999;
}
.project .image-btn-right {
  display: flex;
  position: absolute;
  margin-right: 4vw;
  right: 0;
  top: 50%;
  transform: translateY(-50%) !important;
  margin-left: 5vw;
  z-index: 99999;
}
.project .i-con-white {
  font-size: 4vw;
  padding: 1vw 0.5vw;
  font-size: 10vw;
  color: white;
  background-color: unset !important;
}
.project .slider {
  margin-top: 4vw;
  width: 100vw !important;
  height: 31.93vw !important;
}
.project .slider .swiper {
  background-color: black;
}
.project .slider img {
  height: 31.93vw !important;
}
.project .box-color-background {
  position: absolute;
  top: 26vw;
  width: 100vw;
  height: 30.42vw;
  background-color: white;
  z-index: -99;
}
.villa {
  margin-top: 6vw;
  color: white;
}
.villa .row > * {
  padding-right: 0.6vw;
  padding-left: 0.6vw;
}
.villa .title {
  font-size: 2.605vw;
  margin-left: 10vw;
}
.villa .text-1 {
  font-size: 2.605vw;
}
.villa .text-2 {
  color: #ffefcd;
  font-size: 2.3438vw;
}
.villa .text-3 {
  border: 10px solid white;
  font-size: 3.13vw;
  padding: 1vw;
  height: 26.77vw;
  line-height: 3.13vw;
  padding-top: 3vw;
  padding-right: 4vw;
}
.villa .villa-row {
  padding: 2vw 5vw 4vw 10vw;
  width: 100vw;
}
.villa .flex-show {
  flex: 0 0 auto;
}
.villa .flex-show:first-child {
  width: 34%;
}
.villa .flex-show:nth-child(2) {
  width: 39%;
}
.villa .flex-show:nth-child(3) {
  width: 27%;
}
.room {
  margin-top: 10vw;
}
.room .row {
  /* padding-left: 8vw; */
  padding-top: 2vw;
  margin-left: 0vw;
  overflow: hidden;
}
.room .room-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 0px;
  padding-left: 0px;
}
.room .room-1 img {
  width: 31.01vw;
  height: 31.32vw;
}
.room .room-1 .room1-text {
  width: 100%;
  font-size: 0.886vw;
  background-color: #424530;
  color: white;
  padding: 1vw;
  width: 35.105vw;
}
.sun {
  max-width: 23vw !important;
  position: absolute;
  background-color: rgba(115, 114, 109, 0.5);
  padding: 1vw;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
  opacity: 0.5;
  top: 4vw;
  right: 2vw;
}
.sun .room-title {
  font-size: 1.3vw;
}
.sun .room-sub-title {
  margin-top: 1vw;
  font-size: 0.98vw;
}
.text-sector-1 {
  max-width: 23vw !important;
  position: absolute;
  background-color: rgba(140, 158, 147, 0.5);
  opacity: 0.5;
  padding: 1vw;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
  left: 2vw;
  top: 6vw;
}
.text-sector-1 .room-title {
  font-size: 1.3vw;
}
.text-sector-1 .room-sub-title {
  margin-top: 1vw;
  font-size: 0.98vw;
}
.text-sector-2 {
  max-width: 23vw !important;
  position: absolute;
  background-color: rgba(166, 142, 116, 0.5);
  padding: 1vw;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
  opacity: 0.5;
  bottom: 7vw;
  right: 2vw;
}
.text-sector-2 .room-title {
  font-size: 1.3vw;
}
.text-sector-2 .room-sub-title {
  margin-top: 1vw;
  font-size: 0.98vw;
}
.text-sector-3 {
  display: flex;
  justify-content: center;
  font-size: 2vw;
}
.room-2 .border-show {
  border-top: 0.6vw solid #424530;
}
.room .room-2 {
  width: 61%;
  margin-top: 10vw;
  padding-right: 0px;
  padding-left: 0px;
}
.room .room-2 .room-text1 {
  margin-top: 3vw;
  font-size: 1.05vw;
}
.room .room-2 .room-text2 {
  margin-top: 1vw;
  font-size: 1.6vw;
}
.room .room-2 .room-text3 {
  margin-top: 2vw;
  font-size: 1.05vw;
}
.room .room-2 .room-text4 {
  margin-top: 1vw;
  font-size: 1.6vw;
}
.room .room-2 .room-text5 {
  margin-top: 0.8vw;
  font-size: 1.05vw;
}
.room .room-2 img {
  width: 20.68vw;
  height: 12.084vw;
}
.map {
  margin-top: 20vw;
  width: 95%;
  height: 35.282vw;
  /* background-color1: #a58e74; */
  padding: 5vw;
  padding-right: 1vw !important;
  color: white;
  margin-bottom: 20vw;
  position: relative;
}
.map .map-background {
  margin-top: -5vw;
  margin-left: -5vw;
  position: absolute;
  /* margin-top: 20vw; */
  width: 95%;
  height: 33.282vw;
  background-color: #a58e74;
  padding: 5vw;
  color: white;
  margin-bottom: 20vw;
  z-index: -9999;
}
.map-text1 {
  font-size: 1.303vw;
}
.map-text2 {
  font-size: 1.6vw;
}
.map-text3 {
  margin-top: 2vw;
  max-width: 50vw;
  font-size: 1.25vw;
}
.map-text4 {
  margin-top: 1vw;
  max-width: 50vw;
  font-size: 1.25vw;
}
.map-text3-de {
  font-size: 1.2vw !important;
}
.map-text4-de {
  font-size: 1.2vw !important;
}
.show-map {
  margin-top: -3.5vw;
  margin-left: 1.5vw;
  width: 35.94vw;
  height: 32.16vw;
  background-color: #424530;
}
.show-map .i-map {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.map .show-row {
  display: flex;
}
.map .map-our-text {
  font-size: 2.5vw;
}
@media screen and (max-width: 1729px) and (min-width: 1130px) {
  .map .map-our-text {
    font-size: 2.5vw;
  }
  .villa .text-3 {
    font-size: 2.9vw;
    line-height: 2.9vw;
  }
  .room .room-1 img {
    width: 44vw;
    height: 44.37vw;
  }
  .map .map-text3 {
    max-width: 53vw;
  }
  .map .map-text4 {
    max-width: 53vw;
  }
  .show-map {
    margin-left: 2vw;
  }
  .map {
    padding: 5vw;
    padding-right: 2vw !important;
  }
}
@media screen and (max-width: 1460px) and (min-width: 1130px) {
  .map {
    padding-left: 3vw !important;
    padding-right: 2vw !important;
    width: 97%;
  }
  .map .text-show {
    margin-top: -2.5vw;
  }
  .map-text3 {
    margin-top: 1vw;
    max-width: 50vw !important;
  }
  .map-text4 {
    max-width: 50vw !important;
  }
  .show-map {
    margin-left: 4.5vw;
  }
}
@media screen and (max-width: 1143px) and (min-width: 1130px) {
  .map {
    height: 36vw;
  }
  .show-map {
    height: 33vw;
  }
}
@media screen and (max-width: 1130px) and (min-width: 769px) {
  .map .text-show {
    margin-top: -2.5vw;
  }
  .map {
    padding-left: 3vw !important;
    padding-right: 2vw !important;
    width: 97%;
    height: 40vw;
  }
  .show-map {
    margin-left: 2vw !important;
    height: 39.3vw !important;
  }
  .map-text3 {
    margin-top: 1vw;
    max-width: 50vw !important;
    font-size: 1.65vw;
  }
  .map-text4 {
    max-width: 50vw !important;
    font-size: 1.65vw;
  }
  .main-text {
    font-size: 1.1vw !important;
  }
  .room .room-1 img {
    width: 44vw;
    height: 44.37vw;
  }
  .map .map-background {
    height: 42vw !important;
  }
  .map-text1 {
    font-size: 1.5vw;
  }
  .map-text2 {
    font-size: 2.1vw;
  }
  .map .show-map {
    width: 38.55vw;
    height: 32.43vw;
    margin-left: 10vw;
  }
  .show-row .map-text3-fr {
    font-size: 1.55vw !important;
  }
  .show-row .map-text4-fr {
    font-size: 1.55vw !important;
  }
  .show-row .map-text3-de {
    font-size: 1.5vw !important;
  }
  .show-row .map-text4-de {
    font-size: 1.5vw !important;
  }
}
/* @media screen and (max-width: 960px) and (min-width: 769px) {
  .villa .text-3 {
    font-size: 2.6vw;
    padding: 1.8vw;
  }
} */
@media screen and (max-width: 768px) and (min-width: 431px) {
  .map .map-background {
    height: 85vw !important;
  }
  .room .row {
    height: 140vw;
    padding-top: 35vw;
  }
  .text-sector-1 {
    max-width: unset !important;
    width: 80vw !important;
    left: 50%;
    transform: translateX(-50%);
    padding: 4vw;
    padding-left: 4vw !important;
    padding-right: 4vw !important;
  }
  .text-sector-1 .room-title {
    font-size: 3.06vw;
  }
  .text-sector-1 .room-title-de {
    font-size: 3vw !important;
  }
  .text-sector-1 .room-sub-title {
    font-size: 2.1vw;
  }
  .text-sector-2 {
    max-width: unset !important;
    width: 80vw !important;
    left: 50%;
    transform: translateX(-50%);
    padding: 4vw;
    padding-left: 4vw !important;
    padding-right: 4vw !important;
    bottom: 0vw;
  }
  .text-sector-2 .room-title {
    font-size: 3.06vw;
  }
  .text-sector-2 .room-sub-title {
    font-size: 2.1vw;
  }
  .sun {
    max-width: unset !important;
    width: 80vw !important;
    left: 50%;
    transform: translateX(-50%);
    padding: 4vw;
    padding-left: 4vw !important;
    padding-right: 4vw !important;
    top: 94vw;
    height: 16vw;
  }
  .sun .room-title {
    font-size: 3.06vw;
  }
  .sun .room-sub-title {
    font-size: 2.1vw;
  }
  .text-sector-3 {
    font-size: 3.06vw;
  }
  .project .main-title {
    display: none;
  }
  .project .sub-title {
    display: none;
  }
  .project {
    margin-top: unset;
  }
  .show-image-btn {
    display: none !important;
  }
  .swiper-pagination-bullet-active {
    background-color: #a58e74 !important;
    opacity: 1 !important;
  }
  .project .swiper-wrapper .swiper-slide .image-show {
    margin-left: 0.5vw;
  }
  .swiper-pagination-bullet {
    background-color: rgb(165, 142, 116);
    width: 2.74vw;
    height: 2.74vw;
    opacity: 0.5;
  }
  .villa .text-3 {
    font-size: 2.605vw;
    padding: 1.2vw;
    line-height: 2.605vw;
    padding-right: 3.8vw;
    padding-top: 2.5vw;
  }
  .room .row {
    display: block;
    flex-wrap: unset;
    padding-left: 0px;
  }
  .room .room-1 {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .room .room-1 .room1-text {
    width: 37vw;
    font-size: 1.57vw;
    padding: 1.5vw;
  }
  .room .room-1 img {
    width: 52vw;
    height: 52.5vw;
  }
  .room .room-2 {
    margin-top: 6vw;
    width: 100%;
  }
  .room .room-2 .col-6 {
    width: 100%;
    padding-left: 12.6vw;
    padding-right: 11vw;
  }
  .room .room-2 .col-6 .border-show {
    width: 70%;
    border-top: 0.3vw solid #424530;
  }
  .room .room-2 .room-text3 {
    margin-bottom: 7vw;
  }
  .room .room-2 .room-text1 {
    margin-top: 3vw;
    font-size: 2.61vw;
  }
  .room .room-2 .room-text2 {
    font-size: 3.256vw;
  }
  .room .room-2 .room-text3 {
    font-size: 1.96vw;
  }
  .room .room-2 .room-text4 {
    font-size: 3.256vw;
  }
  .room .room-2 .room-text5 {
    font-size: 1.96vw;
  }
  .room .room-2 img {
    margin-top: 2vw;
    width: 79.17vw;
    height: 43.62vw;
  }
  .project .map {
    margin-top: 15vw;
    width: 100%;
    height: 62.86vw;
  }
  .map .show-map {
    margin-top: 1.5vw;
    margin-left: 2vw;
    width: 43.55vw;
    height: 72.43vw;
  }
  .map .map-text1 {
    font-size: 2.61vw;
  }
  .map .map-text2 {
    font-size: 3.256vw;
  }
  .map .map-text3 {
    margin-top: 1vw;
    max-width: 46vw;
    font-size: 2.3vw;
  }
  .map .map-text4 {
    margin-top: 1vw;
    margin-top: 3vw;
    max-width: 46vw;
    font-size: 2.3vw;
  }
  .show-row .map-text3-fr {
    font-size: 2.2vw !important;
  }
  .show-row .map-text4-fr {
    font-size: 2.2vw !important;
  }
  .show-row .map-text3-de {
    font-size: 2.1vw !important;
  }
  .show-row .map-text4-de {
    margin-top: 2.5vw !important;
    font-size: 2.1vw !important;
  }
}
/* @media screen and (max-width: 672px) and (min-width: 430px) {
  .villa .text-3 {
    font-size: 2.3vw;
    line-height: 2.3vw;
    padding: 2.8vw;
  }
} */
.show-project-div {
  display: flex;
  position: relative;
}
.line-title {
  position: absolute;
  bottom: 0.5vw;
  left: 0;
  width: 0;
  height: 0;
  border: 0.2vw solid #212429;
}
@media screen and (max-width: 430px) and (min-width: 250px) {
  .map .map-our-text {
    font-size: 8vw;
  }
  .map {
    padding-bottom: 10vw;
  }
  .image-list {
    margin-top: 0vw !important;
  }
  .map .map-background {
    height: 190vw !important;
  }
  .text-sector-1 {
    max-width: unset !important;
    width: 80vw !important;
    left: 50%;
    transform: translateX(-50%);
    padding: 4vw;
    padding-left: 4vw !important;
    padding-right: 4vw !important;
  }
  .text-sector-1 .room-title {
    font-size: 3.06vw;
  }
  .text-sector-1 .room-title-de {
    font-size: 3vw !important;
  }
  .text-sector-1 .room-sub-title {
    font-size: 2.1vw;
  }
  .text-sector-2 {
    max-width: unset !important;
    width: 80vw !important;
    left: 50%;
    transform: translateX(-50%);
    padding: 4vw;
    padding-left: 4vw !important;
    padding-right: 4vw !important;
    bottom: 0vw;
  }
  .text-sector-2 .room-title {
    font-size: 3.06vw;
  }
  .text-sector-2 .room-sub-title {
    font-size: 2.1vw;
  }
  .room .row {
    height: 160vw !important;
  }
  .sun {
    max-width: unset !important;
    width: 80vw !important;
    left: 50%;
    transform: translateX(-50%);
    padding: 4vw;
    padding-left: 4vw !important;
    padding-right: 4vw !important;
    top: 113vw;
    height: 16vw;
  }
  .sun .room-title {
    font-size: 3.06vw;
  }
  .sun .room-sub-title {
    font-size: 2.1vw;
  }
  .text-sector-3 {
    font-size: 3.06vw;
  }
  .room-1 img {
    margin-top: 15vw;
  }
  .project .slider {
    margin-top: 0vw;
    width: 100vw !important;
    height: 57.18vw !important;
  }
  .project .swiper-wrapper .swiper-slide .image-show {
    /* margin-left: 0.4vw; */
    margin-left: 0vw;
    width: 100vw !important;
  }
  .project .swiper {
    height: 57.18vw !important;
  }
  /* .project .image-btn-left {
    left: 6vw;
  }
  .project .image-btn-right {
    right: 7vw;
  } */
  .project .i-con-white {
    font-size: 10vw;
  }
  .project .slider img {
    height: 37.21vw !important;
  }
  .project .main-title {
    display: none;
  }
  .project .sub-title {
    display: none;
  }
  .project {
    margin-top: unset;
  }
  .show-image-btn {
    display: none !important;
  }
  .villa .villa-row {
    padding: 2vw 0vw 4vw 6vw;
    width: 100vw;
  }
  .villa .flex-show:last-child {
    display: flex;
    justify-content: flex-end;
    padding-right: 9vw;
  }
  .villa .text-3 {
    font-size: 4.652vw;
    padding: 1.8vw;
    width: 37vw;
    line-height: 4.652vw;
    margin-top: 7vw;
    padding-right: 4vw;
    padding-top: 4vw;
    height: 42.33vw;
  }
  .villa .title {
    padding-left: 4vw;
    margin-left: 0px;
    width: 100%;
    font-size: 4.652vw;
  }
  .villa .text-1 {
    font-size: 4.652vw;
    line-height: 4.652vw;
  }
  .villa .text-2 {
    margin-top: 4vw;
    font-size: 3.5vw;
  }
  .project .villa .flex-show {
    width: 100%;
  }
  .project .box-color-background {
    top: 41vw;
    height: 117.49vw;
  }
  .room .row {
    display: block;
    flex-wrap: unset;
    padding-left: 0px;
    width: 100vw !important;
    margin-left: 0px;
    height: 150vw;
  }

  .room .room-1 {
    display: block;
    width: 100%;
    margin-top: 20vw;
    overflow: hidden;
  }
  .room .room-1 .room1-text {
    width: 100%;
    font-size: 2.8vw;
    padding: 8vw;
    margin-top: -45vw;
    padding-top: 60vw;
    height: 128.14vw;
  }
  .room .room-1 img {
    position: relative;
    width: 69.54vw;
    height: 68.48vw;
    margin-left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
  }
  .room .room-2 {
    margin-top: 6vw;
    width: 100%;
  }
  .room .room-2 .col-6 {
    width: 100%;
    padding-left: 8vw;
    padding-right: 11vw;
  }
  .room .room-2 .col-6 .border-show {
    width: 0%;
    border-top: 0.3vw solid #424530;
  }
  .room .room-2 .room-text3 {
    margin-bottom: 7vw;
  }
  .room .room-2 .room-text1 {
    text-align: center;
    margin-top: 3vw;
    font-size: 4.66vw;
  }
  .room .room-2 .room-text2 {
    text-align: center;
    font-size: 5.82vw;
  }
  .room .room-2 .room-text3 {
    font-size: 3.5vw;
  }
  .room .room-2 .room-text4 {
    text-align: center;
    font-size: 5.82vw;
  }
  .room .room-2 .room-text5 {
    font-size: 3.5vw;
  }
  .room .room-2 img {
    margin-top: 2vw;
    width: 83.26vw;
    height: 48.61vw;
  }
  .project .map {
    margin-top: 10vw;
    width: 100%;
    height: 170vw;
  }
  .map .show-row {
    display: block;
    position: relative;
  }
  .map .text-show {
    text-align: center;
    height: 131.87vw;
    padding-top: 57vw;
  }
  .map .show-map {
    position: absolute;
    width: 100vw;
    height: 57.91vw;
    margin-top: unset;
    margin-left: unset;
    top: -5vw;
    left: -5vw;
  }
  .map .map-text1 {
    font-size: 4.66vw;
  }
  .map .map-text2 {
    font-size: 5.82vw;
  }
  .map .map-text3 {
    text-align: start;
    max-width: unset;
    font-size: 3.9vw;
  }
  .map .map-text4 {
    text-align: start;
    margin-top: 5vw;
    max-width: unset;
    font-size: 3.9vw;
  }
  .show-row .map-text3-de {
    font-size: 3.8vw !important;
  }
  .show-row .map-text4-de {
    font-size: 3.8vw !important;
  }
  .show-row .map-text3-fr {
    font-size: 3.8vw !important;
  }
  .show-row .map-text4-fr {
    margin-top: 3vw;
    font-size: 3.8vw !important;
  }
}
.swiper-backface-hidden .swiper-slide {
  backface-visibility: unset !important;
}
