.footer {
  /* width: 100vw; */
  background-color: #424530;
  padding: 4vw 6vw;
  color: white;
}
.footer .title {
  font-size: 3.13vw;
  text-decoration: underline;
}
.footer .address {
  margin-top: 2vw;
  font-size: 1.6vw;
}

.footer .contact {
  margin-top: 2vw;
  font-size: 1.6vw;
  margin-left: 2vw;
  margin-top: -1vw;
}
.contact .social {
  margin-bottom: 1.2vw;
  width: 3.13vw;
  height: 3.13vw;
  width: max-content;
  display: flex;
}
.social .social-text {
  margin-left: 1vw;
  margin-top: 0.8vw;
}
.footer .footer-logo {
  margin-left: 3vw;
  margin-top: -5vw;
  position: relative;
}
.footer .footer-logo img {
  width: 20.575vw;
  height: 20vw;
  position: absolute;
  right: -23vw;
}
.group-footer {
  display: flex;
}
.footer .address-mobile {
  display: none;
}
.footer .contact .social .image-radius {
  border-radius: 0.5vw;
}
@media (max-width: 430px) {
  .group-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 4.66vw;
  }
  .footer {
    padding: 4vw 4vw;
  }
  .footer .address-mobile {
    margin-top: 9vw;
    font-size: 4.66vw;
    display: block;
  }

  .footer .address {
    display: none;
  }
  .footer .contact {
    margin-left: 5vw;
    margin-top: 3vw;
    font-size: 4.66vw;
    width: 100%;
  }
  .footer .contact .social {
    height: max-content;
  }
  .footer .contact .social .social-text {
    margin-top: 7vw;
    margin-left: 8vw;
  }
  .footer .contact img {
    margin-top: 3vw;
    width: 14vw;
    height: 14vw;
  }
  .footer .footer-logo img {
    position: relative;
    right: unset;
    width: 73.5vw;
    height: 71.2vw;
  }
  .footer .footer-logo {
    margin-left: unset;
    margin-top: unset;
  }
}
